import {
  buildEventPageViewed,
} from './index'

export const SET_EVENT_VIDEO_VIEW_QUALIFIED = 'SET_EVENT_VIDEO_VIEW_QUALIFIED'
export const SET_EVENT_PAGE_VIEWED = 'SET_EVENT_PAGE_VIEWED'
export const SET_EVENT_VIDEO_AUDIO_CHANGED = 'SET_EVENT_VIDEO_AUDIO_CHANGED'
export const CREATE_SERVER_EVENT = 'CREATE_SERVER_EVENT'
export const SET_EVENT_LEAD = 'SET_EVENT_LEAD'
export const SET_DEFAULT_GA4_EVENT = 'SET_DEFAULT_GA4_EVENT'
export const SET_GA4_VARIABLES = 'SET_GA4_VARIABLES'
export const SET_GA4_INIT_EVENT = 'SET_GA4_INIT_EVENT'

export function setEventVideoAudioChanged (options) {
  const { lang } = options
  return {
    type: SET_EVENT_VIDEO_AUDIO_CHANGED,
    payload: { lang },
  }
}

export function setEventDataVideoViewQualified () {
  return {
    type: SET_EVENT_VIDEO_VIEW_QUALIFIED,
  }
}

export function setEventPageViewed (options) {
  const { auth, location, page } = options
  const event = buildEventPageViewed(options)
  return {
    type: SET_EVENT_PAGE_VIEWED,
    payload: {
      auth, event, location, page,
    },
  }
}

export function setEventLead (email) {
  return {
    type: SET_EVENT_LEAD,
    payload: email,
  }
}

export function setDefaultGa4Event (event) {
  return {
    type: SET_DEFAULT_GA4_EVENT,
    payload: { event },
  }
}

export function setGa4Variables (event) {
  return {
    type: SET_GA4_VARIABLES,
    payload: { event },
  }
}

export function setGa4InitEvent () {
  return {
    type: SET_GA4_INIT_EVENT,
  }
}
