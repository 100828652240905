import { Map } from 'immutable'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _omit from 'lodash/omit'
import _assign from 'lodash/assign'
import _isUndefined from 'lodash/isUndefined'
import {
  get, set, parseQuery, hasAmbassadorUTM, queryContainsTracking,
} from './index'

export const SET_INBOUND_TRACKING = 'SET_INBOUND_TRACKING'
export const SET_INBOUND_TRACKING_PATH = 'SET_INBOUND_TRACKING_PATH'
export const SET_INBOUND_TRACKING_DATA = 'SET_INBOUND_TRACKING_DATA'
export const SET_INBOUND_TRACKING_PROCESSING = 'SET_INBOUND_TRACKING_PROCESSING'
export const SET_INBOUND_TRACKING_INITIALIZED = 'SET_INBOUND_TRACKING_INITIALIZED'
export const SET_INBOUND_TRACKING_DATA_INITIALIZED = 'SET_INBOUND_TRACKING_DATA_INITIALIZED'

export function parseInboundTrackingUrl (options) {
  return async function parseInboundTrackingUrlThunk (dispatch) {
    const {
      uid, query, timestamp, path, auth,
    } = options
    if (!queryContainsTracking(query)) {
      dispatch(setInboundTrackingPath(path, false))
    } else {
      dispatch(setInboundTrackingPath(path, true))
    }
    const data = parseQuery(query, timestamp)
    await set({ data, auth, uid }).then((inboundTrackingData) => {
      dispatch(setInboundTrackingData(inboundTrackingData, false))
      return inboundTrackingData
    })
  }
}

export function setInboundTrackingAffiliateData (options) {
  return async function setInboundTrackingPersistentThunk (dispatch) {
    dispatch(setInboundTrackingProcessing(true))
    const {
      auth,
      rfd,
      source,
      sourceId,
      utmSource,
      affiliateId,
      offerId,
      transactionId,
    } = options
    const uid = auth.get('uid')

    try {
      const trackingData = await get({ uid })
      let trackingDataJS = (trackingData || Map()).toJS()
      // get rid of prev ambassador data, if any
      if (hasAmbassadorUTM(trackingDataJS)) {
        // This is some crazy bandaid stuff we do to remove competing rfd and ambassador utm values.
        // while retaining other possible utm values.
        // The strings.utm gets used for checkout, unfortunately.
        const strings = _get(trackingDataJS, 'strings') || {}
        let stringsUTM = (_get(strings, 'utm') || '')
          .replace(/(utm_source|utm_medium)=\w+&?/g, '')

        if (utmSource) {
          stringsUTM = stringsUTM.length > 0
            ? `&utm_source=${utmSource}`
            : `utm_source=${utmSource}`
        }
        _set(strings, 'utm', stringsUTM)
        _set(trackingDataJS, 'strings', strings)

        trackingDataJS = _omit(
          trackingDataJS,
          ['utm_source', 'utm_medium'],
        )
      }

      const updatedTracking = {
        data: _assign({}, trackingDataJS, _omit({
          rfd,
          source,
          sourceId,
          utm_source: utmSource,
          affiliateId,
          offerId,
          transactionId,
        }, _isUndefined)),
        auth,
        uid,
      }
      const inboundTrackingData = await set(updatedTracking)
      dispatch(setInboundTrackingData(inboundTrackingData, false))
    } catch (e) {
      dispatch(setInboundTrackingData(Map(), false))
    }
  }
}

export function getInboundTrackingPersistent (options) {
  return function getInboundTrackingPersistentThunk (dispatch) {
    dispatch(setInboundTrackingProcessing(true))
    const { auth } = options
    get({ uid: auth.get('uid') })
      .then((inboundTracking) => {
        dispatch(
          setInboundTrackingDataInitialized(inboundTracking, true, false),
        )
        return inboundTracking
      })
      .catch(() => {
        dispatch(setInboundTrackingDataInitialized(Map(), true, false))
      })
  }
}

export function setInboundTrackingContentImpression (options) {
  return async function setContentImpressionThunk (dispatch, getState) {
    // eslint-disable-next-line camelcase
    const { uid, ci_type, ci_id } = options
    const { inboundTracking } = getState()
    const inboundTrackingData = inboundTracking.get('data') || Map()
    const userId = uid || -1

    try {
      const trackingData = await get({ uid: userId })
      const trackingDataJS = (trackingData || Ma_FLAG],
  ['curryRight', WRAP_CURRY_RIGHT_FLAG],
  ['flip', WRAP_FLIP_FLAG],
  ['partial', WRAP_PARTIAL_FLAG],
  ['partialRight', WRAP_PARTIAL_RIGHT_FLAG],
  ['rearg', WRAP_REARG_FLAG]
];

/**
 * Updates wrapper `details` based on `bitmask` flags.
 *
 * @private
 * @returns {Array} details The details to modify.
 * @param {number} bitmask The bitmask flags. See `createWrap` for more details.
 * @returns {Array} Returns `details`.
 */
function updateWrapDetails(details, bitmask) {
  arrayEach(wrapFlags, function(pair) {
    var value = '_.' + pair[0];
    if ((bitmask & pair[1]) && !arrayIncludes(details, value)) {
      details.push(value);
    }
  });
  return details.sort();
}

module.exports = updateWrapDetails;
