import _get from 'lodash/get'
import { get as getConfig } from 'config'
import {
  createCheckoutOrder,
  createCheckoutV2Order,
  captureEmail,
  CHECKOUT_ORDER_ERROR_TYPE_UNKNOWN,
} from './index'
import './types'

const config = getConfig()
const asyncCheckoutActive = !!_get(config, ['features', 'checkout', 'asyncCheckout'])

export const SET_CHECKOUT_ACCOUNT_VALID = 'SET_CHECKOUT_ACCOUNT_VALID'
export const SET_CHECKOUT_ACCOUNT_CREATE_DATA = 'SET_CHECKOUT_ACCOUNT_CREATE_DATA'
export const SET_CHECKOUT_USER_DATA = 'SET_CHECKOUT_USER_DATA'
export const SET_CHECKOUT_ORDER_PROCESSING = 'SET_CHECKOUT_ORDER_PROCESSING'
export const SET_CHECKOUT_ORDER_DATA = 'SET_CHECKOUT_ORDER_DATA'
export const SET_CHECKOUT_ORDER_COMPLETE = 'SET_CHECKOUT_ORDER_COMPLETE'
export const SET_CHECKOUT_ORDER_ERROR = 'SET_CHECKOUT_ORDER_ERROR'
export const SET_CHECKOUT_EMAIL_CAPTURE_STATUS = 'SET_CHECKOUT_EMAIL_CAPTURE_STATUS'
export const RESET_CHECKOUT_EMAIL_CAPTURE_STATUS = 'RESET_CHECKOUT_EMAIL_CAPTURE_STATUS'
export const SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP'
export const SET_CHECKOUT_EVENT_STEP = 'SET_CHECKOUT_EVENT_STEP'
export const RESET_CHECKOUT = 'RESET_CHECKOUT'
export const SET_CHECKOUT_PLAN_SELECTION = 'SET_CHECKOUT_PLAN_SELECTION'
export const SET_CHECKOUT_REACTIVATION = 'SET_CHECKOUT_REACTIVATION'
export const SET_CHECKOUT_ORDER_STATUS = 'SET_CHECKOUT_ORDER_STATUS'
export const SET_CHECKOUT_INITIATED = 'SET_CHECKOUT_INITIATED'
export const SET_CHECKOUT_PLAN_PRE_SELECTED = 'SET_CHECKOUT_PLAN_PRE_SELECTED'
export const SET_CHECKOUT_CREATION_SOURCE = 'SET_CHECKOUT_CREATION_SOURCE'
export const SET_CHECKOUT_SINGLE_PAGE_STEP = 'SET_CHECKOUT_SINGLE_PAGE_STEP'

export function processCheckoutOrder (options) {
  const {
    paymentInfo,
    tracking,
    auth,
    startDateOverrideInfo,
    reactivation,
    emailOptIn,
    fbEventId,
    velocityCheckToken,
  } = options
  return async function processCheckoutOrderThunk (dispatch) {
    // do async checkout
    if (asyncCheckoutActive) {
      dispatch(setCheckoutOrderProcessing(true))

      try {
        const orderV2 = await createCheckoutV2Order({
          paymentInfo,
          tracking,
          auth,
          startDateOverrideInfo,
          reactivation,
          emailOptIn,
          fbEventId,
          velocityCheckToken,
        })
        const checkoutId = _get(orderV2, 'checkoutId')

        if (checkoutId) {
          dispatch(setCheckoutOrderStatus(checkoutId))
        } else {
          dispatch(setCheckoutOrderError(CHECKOUT_ORDER_ERROR_TYPE_UNKNOWN))
        }
      } catch (e) {
        dispatch(setCheckoutOrderError(CHECKOUT_ORDER_ERROR_TYPE_UNKNOWN))
        throw e
      }
    } else {
      dispatch(setCheckoutOrderProcessing(true))
      try {
        const order = await createCheckoutOrder({
          paymentInfo,
          tracking,
          auth,
          startDateOverrideInfo,
          reactivation,
          emailOptIn,
          fbEventId,
        })
        const orderErrorCode = _get(order, 'errorCode') ? _get(order, 'errorCode') : CHECKOUT_ORDER_ERROR_TYPE_UNKNOWN
        if (_get(order, 'success')) {
          dispatch(setCheckoutOrderData({ ...order, isReactivation: reactivation }))
        } else {
          dispatch(setCheckoutOrderError(orderErrorCode))
        }
      } catch (e) {
        dispatch(setCheckoutOrderError(CHECKOUT_ORDER_ERROR_TYPE_UNKNOWN))
        throw e
      }
    }
  }
}

export function captureCheckoutEmail (options) {
  return async function captureCheckoutEmailThunk (dispatch) {
    try {
      const res = await captureEmail(options)
      const { success, errorCode } = res
      dispatch(setCheckoutEmailCaptureStatus(success, errorCode))
    } catch (e) {
      dispatch(setCheckoutEmailCaptureStatus(false, null))
    }
  }
}

export function setCheckoutAccountValid (value) {
  return {
    type: SET_CHECKOUT_ACCOUNT_VALID,
    payload: value,
  }
}

export function setCheckoutOrderError (data) {
  return {
    type: SET_CHECKOUT_ORDER_ERROR,
    payload: data,
  }
}

export function setCheckoutAccountCreateData (data) {
  return {
    type: SET_CHECKOUT_ACCOUNT_CREATE_DATA,
    payload: data,
  }
}

export function setCheckoutUserData (data) {
  return {
    type: SET_CHECKOUT_USER_DATA,
    payload: data,
  }
}

export function setCheckoutOrderData (data) {
  return {
    type: SET_CHECKOUT_ORDER_DATA,
    payload: data,
  }
}

export function setCheckoutOrderProcessing (data) {
  return {
    type: SET_CHECKOUT_ORDER_PROCESSING,
    payload: data,
  }
}

export function setCheckoutOrderComplete (data) {
  return {
    type: SET_CHECKOUT_ORDER_COMPLETE,
    payload: data,
  }
}

export function setCheckoutEmailCaptureStatus (status, errorCode) {
  return {
    type: SET_CHECKOUT_EMAIL_CAPTURE_STATUS,
    payload: { status, errorCode },
  }
}

export function resetCheckoutEmailCaptureStatus () {
  return {
    type: RESET_CHECKOUT_EMAIL_CAPTURE_STATUS,
  }
}

export function setCheckoutStep (value) {
  return {
    type: SET_CHECKOUT_STEP,
    payload: value,
  }
}

export function setCheckoutEventStep (value) {
  return {
    type: SET_CHECKOUT_EVENT_STEP,
    payload: value,
  }
}

export function resetCheckout () {
  return {
    type: RESET_CHECKOUT,
  }
}

export function setPlanSelection (payload) {
  return {
    type: SET_CHECKOUT_PLAN_SELECTION,
    payload,
  }
}

export function setCheckoutReactivation (value) {
  return {
    type: SET_CHECKOUT_REACTIVATION,
    payload: value,
  }
}

export function setCheckoutOrderStatus (id, attempts = 0) {
  return {
    type: SET_CHECKOUT_ORDER_STATUS,
    payload: { id, attempts },
  }
}

export function setCheckoutInitiated (initiated) {
  return {
    type: SET_CHECKOUT_INITIATED,
    payload: initiated,
  }
}

export function setCheckoutPlanPreSelected (value) {
  return {
    type: SET_CHECKOUT_PLAN_PRE_SELECTED,
    payload: value,
  }
}

export function setCheckoutCreationSource (value) {
  return {
    type: SET_CHECKOUT_CREATION_SOURCE,
    payload: value,
  }
}

export function setCheckoutSinglePageStep (step, eventStep) {
  return {
    type: SET_CHECKOUT_SINGLE_PAGE_STEP,
    payload: { step, eventStep },
  }
}
