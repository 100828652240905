import { Map, List } from 'immutable'
import * as actions from './actions'

export const initialState = Map()

const MAX_EVENT_SIZE = 50

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case actions.SET_EVENT_PAGE_VIEWED:
      return state.update('data', List(), (data) => {
        if (data.size > MAX_EVENT_SIZE) {
          // eslint-disable-next-line no-param-reassign
          data = data.unshift()
        }
        return data.push(action.payload.event)
      })
    default:
      return state
  }
}
