import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect as connectRedux } from 'react-redux'
import { getBoundActions } from 'actions'
import Link from 'components/Link'
import IconV2 from 'components/Icon.v2'
import { getDOMElementAttrs } from 'services/dom'

export const TYPES = {
  PILL_SECONDARY: 'PILL_SECONDARY',
  ICON_SECONDARY: 'ICON_SECONDARY',
  ICON_PRIMARY: 'ICON_PRIMARY',
  ICON_OUTLINE: 'ICON_OUTLINE',
  ICON_FILL: 'ICON_FILL',
  ICON_PILL: 'ICON_PILL',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  DEFAULT: 'DEFAULT',
  PRIMARY: 'PRIMARY',
  GHOST: 'GHOST',
  ICON: 'ICON',
  PILL: 'PILL',
  LINK: 'LINK',
  ROYAL: 'ROYAL',
  ICON_ROYAL: 'ICON_ROYAL',
}

export const SIZES = {
  DEFAULT: 'DEFAULT',
  XSMALL: 'XSMALL',
  LARGE: 'LARGE',
  SMALL: 'SMALL',
}

function getText ({ children, icon, type }) {
  switch (type) {
    case TYPES.ICON:
    case TYPES.ICON_FILL:
    case TYPES.ICON_PILL:
    case TYPES.ICON_OUTLINE:
    case TYPES.ICON_PRIMARY:
    case TYPES.ICON_SECONDARY:
    case TYPES.ICON_ROYAL:
      return children ? (
        <React.Fragment>
          <IconV2 type={icon} />
          {children}
        </React.Fragment>
      ) : <IconV2 type={icon} />
    default:
      return children
  }
}

function getSize (size) {
  switch (size) {
    case SIZES.XSMALL:
      return 'button--x-small'
    case SIZES.LARGE:
      return 'button--large'
    case SIZES.SMALL:
      return 'button--small'
    default:
      return ''
  }
}

function getType (type) {
  switch (type) {
    case TYPES.PILL_SECONDARY:
      return 'button--pill-secondary'
    case TYPES.ICON_OUTLINE:
      return 'button--icon-outline'
    case TYPES.ICON_PRIMARY:
      return 'button--icon-primary'
    case TYPES.ICON_SECONDARY:
      return 'button--icon-secondary'
    case TYPES.ICON_FILL:
      return 'button--icon-fill'
    case TYPES.ICON_PILL:
      return 'button--icon-pill'
    case TYPES.SECONDARY:
      return 'button--secondary'
    case TYPES.PRIMARY:
      return 'button--primary'
    case TYPES.TERTIARY:
      return 'button--tertiary'
    case TYPES.GHOST:
      return 'button--ghost'
    case TYPES.ICON:
      return 'button--icon'
    case TYPES.PILL:
      return 'button--pill'
    case TYPES.LINK:
      return 'button--link'
    case TYPES.ROYAL:
      return 'button--royal'
    case TYPES.ICON_ROYAL:
      return 'button--icon-royal'
    default:
      return ''
  }
}

function getClass ({
  type,
  size,
  shadow,
  stacked,
  inverted,
  disabled,
  className,
}) {
  const cls = ['button']
  const sizeClass = getSize(size)
  const typeClass = getType(type)
  if (sizeClass) cls.push(sizeClass)
  if (typeClass) cls.push(typeClass)
  if (className) cls.push(className)
  if (stacked) cls.push('button--stacked')
  if (inverted) cls.push('button--inverted')
  if (disabled) cls.push('button--disabled')
  if (shadow) cls.push('button--shadow')
  return cls.join(' ')
}

function Button (props) {
  const {
    className,
    ga4EventData,
    disabled,
    children,
    inverted,
    stacked,
    tabIndex,
    onClick,
    submit,
    onBlur,
    shadow,
    style,
    icon,
    type,
    size,
    url,
    query,
    scrollToTop,
    target,
    directLink,
    element,
  } = props

  const handleOnClick = (e) => {
    e.stopPropagation()
    const { setDefaultGa4Event } = props
    if (!url && !onClick) {
      e.preventDefault()
    }
    if (onClick) {
      if (ga4EventData) setDefaultGa4Event(ga4EventData)
      onClick(e)
    }
  }

  const buttonClass = getClass({
    type, size, stacked, inverted, disabled, className, shadow,
  })
  const text = getText({ children, type, icon })
  const attrs = getDOMElementAttrs(props)

  // if we have a url, output a link ...
  if (url) {
    return (
      <Link
        {...attrs}
        className={buttonClass}
        role="link"
        to={url}
        query={query}
        data-element={element || null}
        onClick={handleOnClick}
        tabIndex={tabIndex}
        scrollToTop={scrollToTop}
        target={target}
        directLink={directLink}
      >
        {text}
      </Link>
    )
  }

  // ... output a button for everything else
  return (
    <button
      {...attrs}
      style={style}
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      onBlur={onBlur}
      tabIndex={tabIndex}
      type={submit ? 'submit' : 'button'}
    >
      {text}
    </button>
  )
}

Button.defaultProps = {
  size: SIZES.DEFAULT,
  type: TYPES.DEFAULT,
  onClick: undefined,
  icon: undefined,
  inverted: false,
  disabled: false,
  stacked: false,
  url: undefined,
  className: '',
  submit: false,
  shadow: false,
  scrollToTop: false,
}

Button.propTypes = {
  url: PropTypes.string,
  submit: PropTypes.bool,
  icon: PropTypes.string,
  onBlur: PropTypes.func,
  style: PropTypes.string,
  onClick: PropTypes.func,
  stacked: PropTypes.bool,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  ga4EventData: ImmutablePropTypes.map,
  size: PropTypes.oneOf([
    SIZES.DEFAULT,
    SIZES.XSMALL,
    SIZES.LARGE,
    SIZES.SMALL,
  ]),
  type: PropTypes.oneOf([
    TYPES.ICON_SECONDARY,
    TYPES.ICON_PRIMARY,
    TYPES.SECONDARY,
    TYPES.ICON_FILL,
    TYPES.ICON_PILL,
    TYPES.TERTIARY,
    TYPES.PRIMARY,
    TYPES.DEFAULT,
    TYPES.GHOST,
    TYPES.ICON,
    TYPES.PILL,
    TYPES.LINK,
    TYPES.ROYAL,
    TYPES.ICON_ROYAL,
  ]),
  shadow: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  directLink: PropTypes.bool,
  element: PropTypes.string,
  target: PropTypes.string,
  query: PropTypes.object,
}

export default connectRedux(
  null,
  (dispatch) => {
    const actions = getBoundActions(dispatch)
    return {
      setDefaultGa4Event: actions.eventTracking.setDefaultGa4Event,
    }
  },
)(Button)
