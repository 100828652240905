import _get from 'lodash/get'
import { post as apiPost, TYPE_BROOKLYN_JSON } from 'api-client'
import { fromJS, Map, List } from 'immutable'
import _toNumber from 'lodash/toNumber'
import { TYPE_CONTENT_SERIES, TYPE_CONTENT_VIDEO } from 'services/content-type'
import {
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_MONTHLY,
  PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_ANNUAL,
  PLAN_SKU_LIVE,
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_MONTHLY,
  PLAN_SKU_INCREASED_PRICE_ANNUAL,
  PLAN_SKU_INCREASED_PRICE_GAIA_PLUS,
} from 'services/plans'

export const FACEBOOK_PIXEL_TEST_CODE_KEY = 'FACEBOOK_PIXEL_TEST_CODE'
export const FACEBOOK_EVENT_NAME_PURCHASE = 'Purchase'
export const FACEBOOK_EVENT_NAME_PAGE_VIEW = 'PageView'
export const FACEBOOK_EVENT_NAME_LEAD = 'Lead'
export const FACEBOOK_EVENT_NAME_INITIATE_CHECKOUT = 'InitiateCheckout'
export const FACEBOOK_EVENT_NAME_VIEW_CONTENT = 'ViewContent'
export const FACEBOOK_EVENT_ACTION_SOURCE = 'website'
export const PURCHASED_MONTHLY_WITH_TRIAL = 'Purchased Monthly With Trial'
export const PURCHASED_MONTHLY_DIRECT = 'Purchased Monthly Direct'
export const PURCHASED_ANNUAL_WITH_TRIAL = 'Purchased Annual With Trial'
export const PURCHASED_ANNUAL_DIRECT = 'Purchased Annual Direct'
export const PURCHASED_EVENTS_PLUS = 'Purchased Events Plus'
export const PURCHASED_PROMOTIONAL_PLAN = 'Purchased Promotional Plan'
export const QUALIFIED = 'Qualified'
export const SERIES_CLICK = 'Series Click'
export const TITLE_CLICK = 'Title Click'
export const PLAY_EVENT = 'Play Event'
export const EVENT_ACTION_PLAY_CLICK = 'Play Click'
export const EVENT_ACTION_TILE_CLICK = 'Click Tile'
export const NOTIFICATIONS = 'Notifications'
export const IMPRESSION_ACTION = 'Impression'
export const EMAIL_OPT_IN_SETTINGS_ON = 'on'
export const EMAIL_OPT_IN_SETTINGS_OFF = 'off'

// Server events
export const SERVER_EVENT_PURCHASE = 'Purchase'
export const SERVER_EVENT_PAGE_VIEW = 'PageView'
export const SERVER_EVENT_LEAD = 'Lead'
export const SERVER_EVENT_INITIATE_CHECKOUT = 'InitiateCheckout'
export const SERVER_EVENT_VIEW_CONTENT = 'ViewContent'

// GA4 events
export const EVENT_PLAY_CLICK = 'play_click'
export const EVENT_PLAY_LIST_CREATED = 'playlist_created'
export const EVENT_PLAY_LIST_DELETED = 'playlist_deleted'
export const EVENT_PLAY_LIST_SHARED = 'playlist_shared'
export const EVENT_REMOVED_FROM_PLAY_LIST = 'removed_from_playlist'
export const EVENT_ADDED_TO_PLAY_LIST = 'added_to_playlist'
export const EVENT_ACCOUNT_PAUSED = 'account_paused'
export const EVENT_ACCOUNT_UNPAUSED = 'account_unpaused'
export const EVENT_ROW_IMPRESSION = 'row_impression'
export const EVENT_TOPIC_CLICKED = 'topic_clicked'
export const EVENT_PRIMARY_LANGUAGE_CHANGED = 'language_changed'
export const EVENT_SECONDARY_LANGUAGE_CHANGED = 'secondary_language_changed'
export const EVENT_EMAIL_CAPTURED = 'email_captured'
export const EVENT_EMAIL_PERMISSION_TOGGLED = 'email_permission_toggled'
export const EVENT_AUTOPLAY_TOGGLED = 'autoplay_toggled'
export const EVENT_COMMENT = 'comment'
export const EVENT_ITEM_REMOVED_FROM_HISTORY = 'item_removed_from_history'
export const EVENT_SERIES_FOLLOWED = 'series_followed'
export const EVENT_SERIES_UNFOLLOWED = 'series_unfollowed'
export const EVENT_NOTIFICATIONS_OPENED = 'notifications_opened'
export const EVENT_NOTIFICATIONS_MANAGE_CLICKED = 'notifications_manage_clicked'
export const EVENT_NOTIFICATIONS_VIEW_ALL_CLICKED = 'notifications_view_all_clicked'
export const EVENT_CANCEL_FLOW_REASON_SELECTED = 'cancel_flow_reason_selected'
export const EVENT_CANCEL_FLOW_FREE_MONTH_SELECTED = 'cancel_flow_free_month_selected'
export const EVENT_CANCEL_FLOW_CHANGE_PLAN_SELECTED = 'cancel_flow_change_plan_selected'
export const EVENT_CANCEL_FLOW_FREE_MONTH_REJECTED = 'cancel_flow_free_month_rejected'
export const EVENT_SPOTLIGHT_TOGGLED = 'spotlight_toggled'
export const EVENT_NAV_CLICKED = 'nav_clicked'
export const EVENT_PROMO_UNIT_IMPRESSION = 'promo_unit_impression'
export const EVENT_PROMO_UNIT_CLICK = 'promo_unit_click'
export const EVENT_CLICK_TO_DETAILS_PAGE = 'click_to_detail_page'
export const EVENT_VIDEO_PLAYER_OPENED = 'video_player_opened'
export const EVENT_VIDEO_LOADS_AND_STARTS_PLAY = 'video_loads_and_starts_to_play'
export const EVENT_SHARE_INITIATED = 'share_initiated'
export const EVENT_SHARE_COMPLETED = 'share_completed'
export const EVENT_VIDEO_QUALIFIED_VIEW = 'video_qualified_view'
export const EVENT_VIDEO_ENGAGED_VIEW = 'video_engaged_view'
export const EVENT_VIDEO_COMPLETED = 'video_completed'
export const EVENT_VIDEO_SPEED_CHANGED = 'video_speed_changed'
export const EVENT_VIDEO_AUDIO_CHANGED = 'video_audio_changed'
export const EVENT_VIDEO_SUBTITLES_SET = 'video_subtitles_set'
export const EVENT_PREVIEW_PLAYED = 'preview_played'
export const EVENT_PLAYER_INFO_TOGGLED = 'player_info_toggled'
export const EVENT_SPOTLIGHT_IMPRESSION = 'spotlight_impression'
export const EVENT_SPOTLIGHT_PLAY_TOGGLE = 'spotlight_play_toggle'
export const EVENT_SPOTLIGHT_AUDIO_TOGGLE = 'spotlight_audio_toggle'
export const EVENT_CREATE_ACCOUNT_PRECURSOR = 'create_account_precursor'
export const EVENT_SIGN_UP = 'sign_up'
export const EVENT_PLAN_SELECT_PRECURSOR = 'plan_select_precursor'
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout'
export const EVENT_PAYMENT_INFO_PAGE = 'payment_info_page'
export const EVENT_PURCHASE = 'purchase'
export const EVENT_PURCHASE_PLAN_UPGRADE = 'purchase_plan_upgrade'
export const EVENT_PURCHASED_MONTHLY_WITH_TRIAL = 'purchased_monthly_with_trial'
export const EVENT_PURCHASED_MONTHLY_DIRECT = 'purchased_monthly_direct'
export const EVENT_PURCHASED_ANNUAL_WITH_TRIAL = 'purchased_annual_with_trial'
export const EVENT_PURCHASED_ANNUAL_DIRECT = 'purchased_annual_direct'
export const EVENT_PURCHASED_EVENTS_PLUS = 'purchased_events_plus'
export const EVENT_PURCHASED_PROMOTIONAL_PLAN = 'purchased_promotional_plan'
export const EVENT_CHECKOUT_UPDATE_EMAIL = 'checkout_update_email'
export const EVENT_FILTER_APPLIED = 'filter_applied'
export const EVENT_CONTENT_RATING = 'content_rating'
export const EVENT_CHROMECAST_CONNECTED = 'chromecast_connected'
export const EVENT_AIRPLAY_CONNECTED = 'airplay_connected'
export const EVENT_CUSTOM_EVENT = 'custom_event'
export const PAYPAL_POPUP_OPENED_EVENT = 'paypal_popup_opened'
export const EVENT_START_FREE_TRIAL_CLICKED = 'start_free_trial_clicked'
export const TAG_CLICKED_EVENT = 'tag_clicked'
export const ARTICLE_CLICKED_EVENT = 'article_clicked'
export const USER_LOGIN_NEW_TO_GAIA_CLICK_EVENT = 'user_login_new_to_gaia_click'
export const NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_EVENT = 'notifications_recommended_permission_toggled'
export const NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_EVENT = 'notifications_new_content_permission_toggled'
export const TESTAROSSA_SPOTLIGHT_CLICKED_EVENT = 'testarossa_spotlight_clicked'
export const PAGEVIEW_EVENT = 'Pageview'
export const EVENT_SHARE_OFFER_CLICKED = 'share_offer_clicked'
export const EVENT_PLAYLIST_TILE_CTA_CLICKED = 'playlist_tile_cta_clicked'
export const EVENT_CHECKOUT_EDIT_CLICKED = 'checkout_edit_clicked'
export const EVENT_LOG_IN_EMAIL_REQUESTED = 'log_in_email_requested'
export const EVENT_LOGGED_IN_WITH_CODE = 'logged_in_with_code'
export const EVENT_LOGGED_IN_WITH_LINK = 'logged_in_with_link'

export const CHECKOUT_STEP_EVENTS = List([
  EVENT_CREATE_ACCOUNT_PRECURSOR,
  EVENT_SIGN_UP,
  EVENT_PLAN_SELECT_PRECURSOR,
  EVENT_BEGIN_CHECKOUT,
  EVENT_PAYMENT_INFO_PAGE,
  EVENT_PURCHASE,
  EVENT_PURCHASE_PLAN_UPGRADE,
  EVENT_PURCHASED_MONTHLY_WITH_TRIAL,
  EVENT_PURCHASED_MONTHLY_DIRECT,
  EVENT_PURCHASED_ANNUAL_WITH_TRIAL,
  EVENT_PURCHASED_ANNUAL_DIRECT,
  EVENT_PURCHASED_EVENTS_PLUS,
  EVENT_PURCHASED_PROMOTIONAL_PLAN,
])

export const EVENT_DEFAULT_VALUES = fromJS({
  properties: {},
})
export const NAVIGATION_EVENT_LABELS = {
  LOGO: 'logo',
  IAF: 'invite a friend',
  CAREER: 'work at gaia',
  LANGUAGE_GLOBE: 'language globe',
  NOTIFICATIONS: 'notifications',
  SETTINGS: 'settings',
  USER_MENU: 'user menu',
}

// GA4 events
export const PLAY_CLICK_GA4_EVENT = fromJS({
  event: EVENT_PLAY_CLICK,
})
export const PLAY_LIST_CREATED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_CREATED,
})
export const PLAY_LIST_DELETED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_DELETED,
})
export const PLAY_LIST_SHARED_GA4_EVENT = fromJS({
  event: EVENT_PLAY_LIST_SHARED,
})
export const REMOVED_FROM_PLAY_LIST_GA4_EVENT = fromJS({
  event: EVENT_REMOVED_FROM_PLAY_LIST,
  ui_location: 'Manage Playlist Modal',
})
export const ADDED_TO_PLAY_LIST_GA4_EVENT = fromJS({
  event: EVENT_ADDED_TO_PLAY_LIST,
  ui_location: 'Manage Playlist Modal',
})
export const ACCOUNT_PAUSED_GA4_EVENT = fromJS({
  event: EVENT_ACCOUNT_PAUSED,
})
export const ACCOUNT_UNPAUSED_GA4_EVENT = fromJS({
  event: EVENT_ACCOUNT_UNPAUSED,
})
export const ROW_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_ROW_IMPRESSION,
})
export const TOPIC_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_TOPIC_CLICKED,
})
export const PRIMARY_LANGUAGE_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_PRIMARY_LANGUAGE_CHANGED,
})
export const SECONDARY_LANGUAGE_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_SECONDARY_LANGUAGE_CHANGED,
})
export const EMAIL_CAPTURED_GA4_EVENT = fromJS({
  event: EVENT_EMAIL_CAPTURED,
})
export const AUTOPLAY_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_AUTOPLAY_TOGGLED,
})
export const COMMENT_GA4_EVENT = fromJS({
  event: EVENT_COMMENT,
})
export const ITEM_REMOVED_FROM_HISTORY_GA4_EVENT = fromJS({
  event: EVENT_ITEM_REMOVED_FROM_HISTORY,
})
export const SERIES_FOLLOWED_GA4_EVENT = fromJS({
  event: EVENT_SERIES_FOLLOWED,
})
export const SERIES_UNFOLLOWED_GA4_EVENT = fromJS({
  event: EVENT_SERIES_UNFOLLOWED,
})
export const NOTIFICATIONS_OPENED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_OPENED,
})
export const NOTIFICATIONS_MANAGE_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_MANAGE_CLICKED,
})
export const NOTIFICATIONS_VIEW_ALL_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NOTIFICATIONS_VIEW_ALL_CLICKED,
})
export const CANCEL_FLOW_REASON_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_REASON_SELECTED,
})
export const CANCEL_FLOW_FREE_MONTH_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_FREE_MONTH_SELECTED,
})
export const CANCEL_FLOW_CHANGE_PLAN_SELECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_CHANGE_PLAN_SELECTED,
})
export const SPOTLIGHT_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_TOGGLED,
})
export const NAV_V1_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v1',
})
export const NAV_V2_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v2',
})
export const NAV_V4_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_NAV_CLICKED,
  nav_version: 'v4',
})
export const PROMO_UNIT_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_PROMO_UNIT_IMPRESSION,
})
export const PROMO_UNIT_CLICK_GA4_EVENT = fromJS({
  event: EVENT_PROMO_UNIT_CLICK,
})
export const CLICK_TO_DETAILS_PAGE_GA4_EVENT = fromJS({
  event: EVENT_CLICK_TO_DETAILS_PAGE,
})
export const VIDEO_PLAYER_OPENED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_PLAYER_OPENED,
})
export const VIDEO_LOADS_AND_STARTS_PLAY_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_LOADS_AND_STARTS_PLAY,
})
export const SHARE_INITIATED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_INITIATED,
  full_share: true, // note: this will change in the future but right now is set to true
})
export const SHARE_COMPLETED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_COMPLETED,
})
export const VIDEO_QUALIFIED_VIEW_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_QUALIFIED_VIEW,
})
export const VIDEO_ENGAGED_VIEW_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_ENGAGED_VIEW,
})
export const VIDEO_COMPLETED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_COMPLETED,
})
export const VIDEO_SPEED_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_SPEED_CHANGED,
})
export const VIDEO_AUDIO_CHANGED_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_AUDIO_CHANGED,
})
export const VIDEO_SUBTITLES_SET_GA4_EVENT = fromJS({
  event: EVENT_VIDEO_SUBTITLES_SET,
})
export const PREVIEW_PLAYED_GA4_EVENT = fromJS({
  event: EVENT_PREVIEW_PLAYED,
})
export const PLAYER_INFO_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_PLAYER_INFO_TOGGLED,
})
export const SPOTLIGHT_IMPRESSION_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_IMPRESSION,
})
export const SPOTLIGHT_PLAY_TOGGLE_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_PLAY_TOGGLE,
})
export const SPOTLIGHT_AUDIO_TOGGLE_GA4_EVENT = fromJS({
  event: EVENT_SPOTLIGHT_AUDIO_TOGGLE,
})
export const CRETATE_ACCOUNT_PRECURSOR_GA4_EVENT = fromJS({
  event: EVENT_CREATE_ACCOUNT_PRECURSOR,
})
export const SIGN_UP_GA4_EVENT = fromJS({
  event: EVENT_SIGN_UP,
})
export const PLAN_SELECT_PRECURSOR_GA4_EVENT = fromJS({
  event: EVENT_PLAN_SELECT_PRECURSOR,
})
export const BEGIN_CHECKOUT_GA4_EVENT = fromJS({
  event: EVENT_BEGIN_CHECKOUT,
})
export const PAYMENT_INFO_PAGE_GA4_EVENT = fromJS({
  event: EVENT_PAYMENT_INFO_PAGE,
})
export const PURCHASE_GA4_EVENT = fromJS({
  event: EVENT_PURCHASE,
})
export const PURCHASED_MONTHLY_WITH_TRIAL_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_MONTHLY_WITH_TRIAL,
})
export const PURCHASED_MONTHLY_DIRECT_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_MONTHLY_DIRECT,
})
export const PURCHASED_ANNUAL_WITH_TRIAL_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_ANNUAL_WITH_TRIAL,
})
export const PURCHASED_ANNUAL_DIRECT_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_ANNUAL_DIRECT,
})
export const PURCHASED_EVENTS_PLUS_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_EVENTS_PLUS,
})
export const PURCHASED_PROMOTIONAL_PLAN_GA4_EVENT = fromJS({
  event: EVENT_PURCHASED_PROMOTIONAL_PLAN,
})
export const FILTER_APPLIED_GA4_EVENT = fromJS({
  event: EVENT_FILTER_APPLIED,
})
export const CONTENT_RATING_GA4_EVENT = fromJS({
  event: EVENT_CONTENT_RATING,
})
export const CHROMECAST_CONNECTED_GA4_EVENT = fromJS({
  event: EVENT_CHROMECAST_CONNECTED,
})
export const AIRPLAY_CONNECTED_GA4_EVENT = fromJS({
  event: EVENT_AIRPLAY_CONNECTED,
})
export const CHECKOUT_UPDATE_EMAIL_GA4_EVENT = fromJS({
  event: EVENT_CHECKOUT_UPDATE_EMAIL,
})
export const CUSTOM_EVENT_GA4_EVENT = fromJS({
  event: EVENT_CUSTOM_EVENT,
})
export const PAYPAL_POPUP_OPENED_GA4_EVENT = fromJS({
  event: PAYPAL_POPUP_OPENED_EVENT,
})
export const START_FREE_TRIAL_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_START_FREE_TRIAL_CLICKED,
})
export const TAG_CLICKED_GA4_EVENT = fromJS({
  event: TAG_CLICKED_EVENT,
})
export const ARTICLE_CLICKED_GA4_EVENT = fromJS({
  event: ARTICLE_CLICKED_EVENT,
})
export const USER_LOGIN_NEW_TO_GAIA_CLICK_GA4_EVENT = fromJS({
  event: USER_LOGIN_NEW_TO_GAIA_CLICK_EVENT,
})
export const NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: NOTIFICATIONS_RECOMMENDED_PERMISSION_TOGGLED_EVENT,
})
export const NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: NOTIFICATIONS_NEW_CONTENT_PERMISSION_TOGGLED_EVENT,
})
export const TESTAROSSA_SPOTLIGHT_CLICKED_GA4_EVENT = fromJS({
  event: TESTAROSSA_SPOTLIGHT_CLICKED_EVENT,
})
export const PAGEVIEW_GA4_EVENT = fromJS({
  event: PAGEVIEW_EVENT,
})
export const CANCEL_FLOW_FREE_MONTH_REJECTED_GA4_EVENT = fromJS({
  event: EVENT_CANCEL_FLOW_FREE_MONTH_REJECTED,
})
export const EMAIL_PERMISSION_TOGGLED_GA4_EVENT = fromJS({
  event: EVENT_EMAIL_PERMISSION_TOGGLED,
})
export const PURCHASE_PLAN_UPGRADE_GA4_EVENT = fromJS({
  event: EVENT_PURCHASE_PLAN_UPGRADE,
})
export const SHARE_OFFER_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_SHARE_OFFER_CLICKED,
})
export const PLAYLIST_TILE_CTA_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_PLAYLIST_TILE_CTA_CLICKED,
})
export const CHECKOUT_EDIT_CLICKED_GA4_EVENT = fromJS({
  event: EVENT_CHECKOUT_EDIT_CLICKED,
  ui_location: 'simplified checkout',
})

export const LOG_IN_EMAIL_REQUESTED_GA4_EVENT = fromJS({
  event: EVENT_LOG_IN_EMAIL_REQUESTED,
})

export const LOGGED_IN_WITH_CODE_GA4_EVENT = fromJS({
  event: EVENT_LOGGED_IN_WITH_CODE,
})

export const LOGGED_IN_WITH_LINK_GA4_EVENT = fromJS({
  event: EVENT_LOGGED_IN_WITH_LINK,
})

export function getNavClickedGa4Event (navVersion) {
  let navClickedEvent = fromJS({})
  switch (navVersion) {
    case 'v4':
      navClickedEvent = NAV_V4_CLICKED_GA4_EVENT
      break
    case 'v1':
      navClickedEvent = NAV_V1_CLICKED_GA4_EVENT
      break
    case 'v2':
    default:
      navClickedEvent = NAV_V2_CLICKED_GA4_EVENT
      break
  }

  return navClickedEvent
}

function getPurchaseEventData (planSku) {
  let eventData
  switch (planSku) {
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
      eventData = {
        name: PURCHASED_MONTHLY_WITH_TRIAL,
        event: EVENT_PURCHASED_MONTHLY_WITH_TRIAL,
        value: 60,
      }
      break
    case PLAN_SKU_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_MONTHLY:
      eventData = {
        name: PURCHASED_MONTHLY_DIRECT,
        event: EVENT_PURCHASED_MONTHLY_DIRECT,
        value: 120,
      }
      break
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
      eventData = {
        name: PURCHASED_ANNUAL_WITH_TRIAL,
        event: EVENT_PURCHASED_ANNUAL_WITH_TRIAL,
        value: 130,
      }
      break
    case PLAN_SKU_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ANNUAL:
      eventData = {
        name: PURCHASED_ANNUAL_DIRECT,
        event: EVENT_PURCHASED_ANNUAL_DIRECT,
        value: 220,
      }
      break
    case PLAN_SKU_LIVE:
    case PLAN_SKU_INCREASED_PRICE_GAIA_PLUS:
      eventData = {
        name: PURCHASED_EVENTS_PLUS,
        event: EVENT_PURCHASED_EVENTS_PLUS,
        value: 400,
      }
      break
    default:
      eventData = {
        name: PURCHASED_PROMOTIONAL_PLAN,
        event: EVENT_PURCHASED_PROMOTIONAL_PLAN,
        value: 60,
      }
      break
  }

  return eventData
}

export function sentAtDate (date = new Date()) {
  return date.toISOString()
}

export function buildEventPageViewed (data) {
  const { upstreamContext, gaiaScreen } = data
  const newEvent = Map({
    event: 'Page Viewed',
  })

  return createDefaultEvent(data)
    .mergeDeep(createCustomContextProperties(upstreamContext))
    .mergeDeep(createGaiaScreenProperties(gaiaScreen))
    .mergeDeep(newEvent)
}

export function createDefaultEvent (data) {
  const {
    auth,
    page = Map(),
    location,
    windowLocation = global.location,
    date,
    app = Map(),
    language = List(),
  } = data

  const userId = auth ? auth.get('uid', 0) : -1

  const newEvent = fromJS({
    timestamp: sentAtDate(date),
    userId,
    context: {
      app: {
        name: app.get('name'),
        version: app.get('version'),
      },
      page: {
        hash: _get(location, 'hash'),
        path: _get(location, 'pathname'),
        search: _get(location, 'search'),
        title: page.get('title'),
        url: _get(windowLocation, 'href', null),
      },
      traits: {
        preferredLanguages: language.toJS(),
      },
    },
  })
  return EVENT_DEFAULT_VALUES.mergeDeep(newEvent)
}

/**
 * Creates an event properties node for tracking events
 * @param  {Immutable.Map} data map of single-level event properties
 * @return {Immutable.Map} event-final structured properties
 */
export function createCustomContextProperties (data = Map()) {
  if (Map.isMap(data) && data.size > 0) {
    let contentType = data.get('contentType')
    let contentId = data.get('contentId')
    if (data.has('videoId')) {
      contentType = TYPE_CONTENT_VIDEO
      contentId = data.get('videoId')
    } else if (data.has('seriesId')) {
      contentType = TYPE_CONTENT_SERIES
      contentId = data.get('seriesId')
    }
    let properties = fromJS({
      contentId,
      contentType,
      properties: {
        gaiaContext: data,
      },
    })
    if (data.has('videoSpeed')) {
      properties = properties.setIn(
        ['properties', 'videoSpeed'],
        data.get('videoSpeed'),
      )
    }
    if (data.has('isPictureInPictureToggled')) {
      properties = properties.setIn(
        ['properties', 'isPictureInPictureToggled'],
        data.get('isPictureInPictureToggled'),
      )
    }
    return properties
  }
  return Map()
}

/**
 * Creates an event properties node for tracking events
 * @param  {Immutable.Map} data map of single-level event properties
 * @return {Immutable.Map} event-final structured properties
 */
export function createGaiaScreenProperties (data = Map()) {
  if (Map.isMap(data) && data.size > 0) {
    const properties = fromJS({
      properties: {
        gaiaScreen: data,
      },
    })
    return properties
  }

  return Map()
}

/**
 * Sends event data to server
 * @param  {Immutable.Map} data   map of single-level event properties
 */
export async function postEventData (options) {
  try {
    const { auth, data, facebookPixelTestCode } = options
    const path = facebookPixelTestCode ? `v1/events?test=${facebookPixelTestCode}` : 'v1/events'
    apiPost(path, data, { auth }, TYPE_BROOKLYN_JSON)
    // we don't need to store anything from this response right now
  } catch (e) {
    // silent fail
  }
  return null
}

/**
 * Returns the event object for the purchased 'planSku' event
 * @param  {String} planSku string with the sku of the purchased plan
 * @return {Immutable.Map} event data
 */
export function getPurchasedPlanEventData (planSku) {
  let purchasedPlanEventData = null
  switch (planSku) {
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_MONTHLY:
      purchasedPlanEventData = PURCHASED_MONTHLY_WITH_TRIAL_GA4_EVENT
      break
    case PLAN_SKU_MONTHLY:
    case PLAN_SKU_INCREASED_PRICE_MONTHLY:
      purchasedPlanEventData = PURCHASED_MONTHLY_DIRECT_GA4_EVENT
      break
    case PLAN_SKU_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ONE_WEEK_FREE_TRIAL_TO_ANNUAL:
      purchasedPlanEventData = PURCHASED_ANNUAL_WITH_TRIAL_GA4_EVENT
      break
    case PLAN_SKU_ANNUAL:
    case PLAN_SKU_INCREASED_PRICE_ANNUAL:
      purchasedPlanEventData = PURCHASED_ANNUAL_DIRECT_GA4_EVENT
      break
    case PLAN_SKU_LIVE:
    case PLAN_SKU_INCREASED_PRICE_GAIA_PLUS:
      purchasedPlanEventData = PURCHASED_EVENTS_PLUS_GA4_EVENT
      break
    default:
      purchasedPlanEventData = PURCHASED_PROMOTIONAL_PLAN_GA4_EVENT
      break
  }

  return purchasedPlanEventData
}

/**
 * Builds the ecommerce data for the checkout events
 * @param  {Immutable.Map} selectedPlan map with the selected plan data
 * @param  {String} transactionId string with the id of the transaction
 * @return {Object} ecommerce object
 */
export function buildCheckoutEcommerceObject (selectedPlan, transactionId) {
  if (!Map.isMap(selectedPlan) && selectedPlan.size === 0) return {}

  const sku = selectedPlan.get('sku')
  const currency = selectedPlan.get('currencyIso')
  const itemName = selectedPlan.get('name')
  const price = List.isList(selectedPlan.get('costs'))
    ? _toNumber(parseFloat(selectedPlan.get('costs').last()).toFixed(2))
    : 0

  const purchaseEventData = getPurchaseEventData(sku)
  const itemsArray = [
    {
      item_id: sku,
      item_name: itemName,
      price,
    },
  ]

  return {
    ecommerce: {
      currency,
      ...(transactionId && { transaction_id: transactionId }),
      value: purchaseEventData.value,
      price,
      items: itemsArray,
    },
  }
}
