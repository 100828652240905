export const DEFAULT_PATH_YOGA = 'yoga/practices'
export const DEFAULT_PATH_FITNESS = 'yoga/fitness-and-pilates'
export const DEFAULT_PATH_FILM = 'films-docs/all-films'
export const DEFAULT_PATH_RECIPE = 'recipes/all-recipes'
export const DEFAULT_PATH_ORIGINAL = 'all-series/series'
export const DEFAULT_PATH_CONSCIOUS = 'transformation/expanded-consciousness'
export const DEFAULT_PATH_MEDITATION = 'meditation/practices'
// General
export const URL_GAIA_PROD = 'https://www.gaia.com'
export const URL_GET_STARTED = '/get-started'
export const URL_HOME = '/'
export const URL_LOGIN = '/login'
export const URL_LOGIN_WITH_LINK = '/login-with-link'
export const URL_LOGOUT = '/logout'
export const URL_YOGA = '/yoga'
export const URL_TRANSFORMATION = '/transformation'
export const URL_SEEKING_TRUTH = '/seeking-truth'
export const URL_FILMS_DOCS = '/films-docs'
export const URL_FMTV = '/fmtv'
export const URL_RECENTLY_ADDED = '/recently-added'
export const URL_POST_EVENT_LIVE_PAGE = '/live-postevent'
export const URL_CONTACT = 'https://www.gaia.com/contact'
export const URL_HELP_CENTER = 'https://gaiasupportcenter.zendesk.com/hc/en-us'
export const URL_HELP_CENTER_ES = 'https://gaiasupportcenter.zendesk.com/hc/es'
export const URL_HELP_CENTER_FR = 'https://gaiasupportcenter.zendesk.com/hc/fr'
export const URL_HELP_CENTER_DE = 'https://gaiasupportcenter.zendesk.com/hc/de'
export const URL_EMAIL_CUSTOMER_SERVICE_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/requests/new'
export const URL_EMAIL_CUSTOMER_SERVICE = 'https://gaiasupportcenter.zendesk.com/hc/en-us/requests/new'
export const URL_EMAIL_CUSTOMER_SERVICE_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/requests/new'
export const URL_EMAIL_CUSTOMER_SERVICE_FR = 'https://gaiasupportcenter.zendesk.com/hc/fr/requests/new'
export const URL_FAQ = 'https://gaiasupportcenter.zendesk.com/hc/en-us/categories/203065888-Getting-Started'
export const URL_FAQ_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/categories/203065888-Getting-Started'
export const URL_ROKU_APP_STORE = 'https://channelstore.roku.com/details/9354/gaia'
export const URL_ANDROID_APP_STORE = 'https://play.google.com/store/apps/details?id=com.gaiamtv&hl=en'
export const URL_APPLE_APP_STORE = 'https://itunes.apple.com/us/app/gaia-conscious-yoga-meditation-and-spirituality/id821068605?mt=8'
export const URL_AMAZON_APP_STORE_FIRE_TV = 'https://www.amazon.com/Gaia-Fire-TV-Meditation-Spirituality/dp/B01N9SQC83/ref=sr_1_1?s=mobile-apps&ie=UTF8&qid=1487105671&sr=1-1&keywords=gaia'
export const URL_AMAZON_APP_STORE_FIRE_TABLET = 'https://www.amazon.com/Gaia-Conscious-Yoga-Meditation-Spirituality/dp/B00IPXJWG2/ref=sr_1_3?keywords=gaia&qid=1580762018&s=mobile-apps&sr=1-3'
export const URL_WATCH_GAIA_ON_TV = 'https://www.gaia.com/lp/get-gaia-on-your-tv/'
export const URL_APPLE_ITUNES_MANAGE_SUBSCRIPTION = 'https://support.apple.com/en-us/HT202039'
export const URL_APPLE_ITUNES_FAQ = 'https://support.apple.com/en-us/HT202039'
export const URL_GOOGLE_PAY_MANAGE_SUBSCRIPTION = 'https://myaccount.google.com/subscriptions?continue=https%3A%2F%2Fmyaccount.google.com%2Fpayments-and-subscriptions'
export const URL_ROKU_MANAGE_SUBSCRIPTION = 'https://my.roku.com/account/subscriptions'
export const URL_ROKU_FAQ = 'https://support.roku.com/article/208756478-how-do-i-manage-or-cancel-a-channel-subscription-'
export const URL_PAYPAL_RECURRING_MANAGE_SUBSCRIPTION = 'https://www.paypal.com/myaccount/autopay/'
export const URL_ANDROID_PAY_MANAGE_SUBSCRIPTION = 'https://pay.google.com/#subscriptionsAndServices'
export const URL_AMAZON_MANAGE_SUBSCRIPTION = 'https://www.amazon.com/gp/help/customer/display.html?nodeId=GDT2SXJJ7K49JKYE'
export const URL_FMTV_HELP_DESK = 'https://foodmatters.freshdesk.com/support/home'
export const URL_TERMS_PRIVACY = '/terms-privacy'
export const URL_CHROMECAST = 'https://play.google.com/store/apps/details?utm_source=google+paid&utm_medium=cpc&utm_term=gaia&utm_campaign=1-USA-Brand-Gaia-Broad-Isolated&utm_content=Gaia-Broad&ch=br&gclid=EAIaIQobChMIvJHU7bG-6QIVT77ACh1WkAyuEAAYASAAEgL89vD_BwE&id=com.gaiamtv&hl=en'
export const URL_TECHNICAL_PLAY_ISSUES_EN = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360046017331-I-am-experiencing-playback-issues-on-my-computer-or-laptop-What-should-I-do-'
export const URL_TECHNICAL_PLAYBACK_ISSUES_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/articles/360046017331-Ich-habe-Probleme-bei-der-Wiedergabe-auf-meinem-Computer-oder-Laptop-Was-soll-ich-tun-'
export const URL_TECHNICAL_PLAYBACK_ISSUES_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/articles/360046017331-Estoy-experimentando-problemas-de-reproducci%C3%B3n-en-mi-computadora-o-Laptop-Qu%C3%A9-debo-hacer-'
export const URL_TECHNICAL_OFFLINE_CONTENT_DOWNLOAD_EN = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/115003810312-Can-I-watch-videos-offline-with-the-Gaia-App-'
export const URL_TECHNICAL_OFFLINE_CONTENT_DOWNLOAD_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/articles/115003810312-Kann-ich-Videos-herunterladen-um-sie-mit-der-Gaia-App-offline-anzusehen-'
export const URL_TECHNICAL_OFFLINE_CONTENT_DOWNLOAD_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/articles/115003810312--Puedo-descargar-videos-para-ver-offline-con-la-aplicaci%C3%B3n-Gaia-'
export const URL_TECHNICAL_SETUP_CONTENT_TV_EN = 'https://www.gaia.com/lp/get-gaia-on-your-tv'
export const URL_TECHNICAL_SETUP_CONTENT_TV_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/categories/203067127--Ger%C3%A4te-und-technischer-Support'
export const URL_TECHNICAL_SETUP_CONTENT_TV_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/categories/203067127--Dispositivo-y-soporte-t%C3%A9cnico'
export const URL_TECHNICAL_TROUBLE_FINDING_CONTENT_EN = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360048416631-Find-Content-You-Love-on-Gaia'
export const URL_TECHNICAL_TROUBLE_FINDING_CONTENT_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/articles/360048416631-Encuentra-en-Gaia-el-contenido-que-te-gusta'
export const URL_TECHNICAL_TROUBLE_FINDING_CONTENT_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/articles/360048416631-Finde-Inhalte-auf-Gaia-die-du-liebst'
export const URL_TECHNICAL_HELP_EN = 'https://gaiasupportcenter.zendesk.com/hc/en-us/categories/203067127-Device-and-Technical-Help'
export const URL_TECHNICAL_HELP_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/categories/203067127--Geräte-und-technischer-Support'
export const URL_TECHNICAL_HELP_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/categories/203067127--Dispositivo-y-soporte-técnico'
export const URL_CANCEL_ACCOUNT_HELP = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/226578588-How-do-I-unsubscribe-or-cancel-my-account-'
export const URL_CANCEL_ACCOUNT_HELP_ES = 'https://gaiasupportcenter.zendesk.com/hc/es/articles/226578588-How-do-I-unsubscribe-or-cancel-my-account-'
export const URL_CANCEL_ACCOUNT_HELP_FR = 'https://gaiasupportcenter.zendesk.com/hc/fr/articles/226578588-How-do-I-unsubscribe-or-cancel-my-account-'
export const URL_CANCEL_ACCOUNT_HELP_DE = 'https://gaiasupportcenter.zendesk.com/hc/de/articles/226578588-How-do-I-unsubscribe-or-cancel-my-account-'
export const URL_MULTIPLE_PROFILE_HELP = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360031442331-How-do-I-use-the-Multiple-Profiles-feature'
export const URL_SHARE_FEATURE_HELP = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360029088452-Can-I-share-Gaia-videos-with-non-members'
export const URL_PAYMENT_TYPES_HELP = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360030736491-What-forms-of-payment-does-Gaia-accept'
export const URL_FREE_TRIAL_HELP = 'https://gaiasupportcenter.zendesk.com/hc/en-us/articles/360050752792-Free-Trials-on-Gaia-FAQ'
export const URL_SOCIAL_ICON_FACEBOOK = 'https://www.facebook.com/gaia'
export const URL_SOCIAL_ICON_FACEBOOK_ES = 'https://www.facebook.com/TueresGaia'
export const URL_SOCIAL_ICON_FACEBOOK_FR = 'https://www.facebook.com/gaiafrancais'
export const URL_SOCIAL_ICON_FACEBOOK_DE = 'https://www.facebook.com/GaiaDeutschland'
export const URL_SOCIAL_ICON_FACEBOOK_YOGA = 'https://www.facebook.com/yogaongaia'
export const URL_SOCIAL_ICON_TWITTER = 'https://www.twitter.com/yourmothergaia'
export const URL_SOCIAL_ICON_TWITTER_YOGA = 'https://www.twitter.com/yogaongaia'
export const URL_SOCIAL_ICON_INSTAGRAM = 'https://www.instagram.com/wearegaia'
export const URL_SOCIAL_ICON_INSTAGRAM_ES = 'https://www.instagram.com/tueresgaia'
export const URL_SOCIAL_ICON_INSTAGRAM_FR = 'https://www.instagram.com/gaia_francais'
export const URL_SOCIAL_ICON_INSTAGRAM_DE = 'https://www.instagram.com/wirsindgaia'
export const URL_SOCIAL_ICON_INSTAGRAM_YOGA = 'https://www.instagram.com/yogaongaia'
export const URL_SOCIAL_ICON_YOUTUBE = 'https://www.youtube.com/user/gaiamtv'
export const URL_SOCIAL_ICON_YOUTUBE_ES = 'https://www.youtube.com/channel/UCxGwnaNhSiezGV6wCx2Iq8Q'
export const URL_SOCIAL_ICON_YOUTUBE_FR = 'https://www.youtube.com/channel/UC2Bb6NMXX-TNhPNSSd1WRsQ'
export const URL_SOCIAL_ICON_YOUTUBE_DE = 'https://www.youtube.com/channel/UCGnvXDDv6zWexmC492Y21sw'
export const URL_SOCIAL_ICON_PINTEREST = 'https://www.pinterest.com/wearegaia'
export const URL_SOCIAL_ICON_PINTEREST_YOGA = 'https://www.pinterest.com/yogaongaia'
export const URL_PLAN_SELECTION = '/plan-selection'
export const URL_PLAN_SELECTION_PLANS = '/plan-selection/plans'
export const URL_ACCOUNT_CREATION = '/cart/account-creation'
export const URL_CART_ACCOUNT_CREATION_CREATE = '/cart/account-creation/create'
export const URL_CART_BILLING = '/cart/billing'
export const URL_CART_BILLING_PAYMENT = '/cart/billing/payment'
export const URL_CART_CONFIRMATION = '/cart/confirmation'
export const URL_CHECKOUT = '/checkout'
export const URL_GO_PAGE = '/go'
export const URL_REMOTE_ACCOUNT_CREATE = '/remote-account-create'
export const URL_CART_ACCESS_DENIED = '/cart/access-denied'
export const URL_TOPICS = '/topics'
export const URL_MEDITATION_PRACTICES = '/meditation/practices'
export const URL_YOGA_SERIES = '/yoga/yoga-series'
export const URL_PILATES_FITNESS = '/yoga/fitness-and-pilates'
export const URL_YOGA_PRACTICES = '/yoga/practices'
export const URL_YOGA_LIFESTYLE = '/yoga/philosophy'
export const URL_YOGA_TEACHERS = '/yoga/teachers'
export const URL_SEARCH = '/search'
export const URL_SHOP = '/shop'

// Params
export const PARAM_LOGIN_ASSISTANCE_FORM_ID = 'ticket_form_id=360000892371' // zendesk account and login assistance

// Account
export const URL_ACCOUNT = '/account'
export const URL_ACCOUNT_CHANGE_PLAN = '/account/change-plan'
export const URL_ACCOUNT_CANCEL = '/account/cancel'
export const URL_ACCOUNT_CANCEL_PAUSE = '/account/cancel/pause'
export const URL_ACCOUNT_CANCEL_OFFER = '/account/cancel/offer'
export const URL_ACCOUNT_CANCEL_CONFIRM = '/account/cancel/confirm'
export const URL_ACCOUNT_CANCEL_FREE_MONTH = '/account/free-month'
export const URL_ACCOUNT_PAUSE = '/account/pause'
export const URL_ACCOUNT_PAUSE_CONFIRM = '/account/pause/confirm'
export const URL_EMAIL_SETTINGS = '/email-settings'
export const URL_ACCOUNT_INVOICE = '/account/invoice'
export const URL_ACCOUNT_UPDATE_PAYMENT = '/account/update-payment'

// Plan Grid V2
export const URL_ARCHIVED_WORKSHOPS = '/topic/watch-on-demand'

// Portal
export const URL_PORTAL = '/portal'

// Refer a Friend
export const URL_REFER_JOIN = '/invite/join'
export const URL_REFER = '/account/referrals'

// Profile
export const URL_ACCOUNT_MAIN = '/account'
export const URL_ACCOUNT_PROFILE = '/account/profile'
export const URL_ACCOUNT_SETTINGS = '/account/settings'
export const HASHTAG = '#'
export const FOLLOW_SETTINGS = 'FollowSettings'
export const NOTIFICATION_SETTINGS = 'NotificationSettings'
export const DISPLAY_SETTINGS = 'DisplaySettings'
export const LANGUAGE_SETTINGS = 'LanguageSettings'
export const COMMUNICATION_SETTINGS = 'CommunicationSettings'
export const HIDDEN_CONTENT_SETTINGS = 'HiddenContentSettings'
export const REMOVED_ROWS_SETTINGS = 'RemovedRowsSettings'
export const HASHTAG_FOLLOW_SETTINGS = HASHTAG + FOLLOW_SETTINGS

// Gift
export const URL_GIFT = '/gift'
export const URL_GIFT_LP = '/lp/give-the-gift-of-gaia'
export const URL_GIFT_CREATE = '/gift/:sku'
export const URL_GIFT_COMPLETE = '/gift/:sku/complete'
export const URL_GIFT_REVIEW = '/gift/:sku/review'
export const URL_GIFT_PAYMENT = '/gift/:sku/payment'
export const URL_GIFT_REDEEM = '/gift/redeem'
export const URL_GIFT_TERMS_AND_CONDITIONS = '/gift-terms-and-conditions'

// Optimizely
export const OPTIMIZELY_DISABLED = 'optimizelyDisabled'

// Live
export const URL_LIVE_PAGE = '/live'

// Gaia+ (formerly known as Events+, and before that, Live Access)
export const URL_GAIA_PLUS = '/gaia-plus'
export const URL_GAIA_PLUS_EVENTS = '/gaia-plus/events'
export const URL_GAIA_PLUS_ALL_GUIDES = '/gaia-plus/all-guides'

// Playlists
export const URL_PLAYLIST = '/playlist'
export const URL_PLAYLIST_WATCH_LATER = '/playlist/default'
export const URL_PLAYLIST_FAVORITES = '/playlist/favorites'
export const URL_PLAYLIST_WATCH_HISTORY = '/playlist/watch-history' // TODO: this shold change in future story.
export const URL_PLAYLIST_ANONYMOUS = '/playlist/anonymous'

// Ambassadors
export const URL_AMBASSADORS = '/ambassadors'

// Url Query Params
export const URL_PARAM_PAYPAL_PAYMENT = 'paypalPayment'

// Url Query Param Values
export const URL_PARAM_VALUE_PAYPAL_PAYMENT = 'true'

// error routes
export const URL_FORBIDDEN = '/errors/403'
export const URL_NOT_FOUND = '/errors/404'
export const URL_ERROR = '/errors/500'
export const URL_UNAVAILABLE = '/errors/503'

// hubs urls
export const URL_HUBS_ANCIENT_WISDOM = '/seeking-truth/ancient-origins'
export const URL_HUBS_PERSONAL_DEVELOPMENT = '/transformation/personal-development'
export const URL_HUBS_ALTERNATIVE_HEALTH = '/topic/alternative-healing'

// marketplace
export const URL_MARKETPLACE = 'https://marketplace.gaia.com/'
export const URL_MARKETPLACE_LOGGED_IN = '/shop'
export const URL_MARKETPLACE_LOGGED_OUT = 'https://shop.gaia.com/'
export const URL_MARKETPLACE_LOGGED_OUT_TEST = 'https://gaiamarketplace.myshopify.com/'

// coming soon
export const URL_COMING_SOON = 'https://www.gaia.com/lp/coming-soon'
export const URL_COMING_SOON_ES = 'https://www.gaia.com/es/lp/nuevas-series'
export const URL_COMING_SOON_FR = 'https://www.gaia.com/fr/lp/les-nouveautes-sur-gaia-2'
export const URL_COMING_SOON_DE = 'https://www.gaia.com/de/lp/demnachst-auf-gaia'
