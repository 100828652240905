import set from 'lodash/set'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import assign from 'lodash/assign'
import toLower from 'lodash/toLower'
import { List, Map } from 'immutable'
import { SET_AUTH_DATA } from 'services/auth/actions'
import { BOOTSTRAP_PHASE_INIT } from 'services/app'
import { SET_APP_BOOTSTRAP_PHASE } from 'services/app/actions'
import { SET_CHECKOUT_ORDER_DATA } from 'services/checkout/actions'
import { SET_RESOLVER_LOCATION } from 'services/resolver/actions'
import { SET_ONBOARDING_COMPLETED } from 'services/onboarding/actions'
import { SET_INBOUND_TRACKING_DATA_INITIALIZED } from 'services/inbound-tracking/actions'
import { SET_USER_DATA_LANGUAGE_PRIMARY, SET_USER_DATA_LANGUAGE } from 'services/user/actions'
import { SET_COOKIE_BANNER_ACCEPTED } from 'services/cookie/actions'
import { get as getConfig } from 'config'
import { canSetCookie } from 'services/local-preferences'
import { getAuthIsLoggedIn } from 'services/auth'
import {
  setRecords,
  setInitialized,
  setInitialState,
  setContext as setReduxContext,
} from './actions'
import { isDisabled, setContext, hasInitialized } from '.'

const stamp = new Date()
const config = getConfig()
const serverTestarossa = get(config, ['servers', 'testarossa'])
const oneTrustEnabled = !!get(config, ['features', 'oneTrust'])

const TESTAROSSA_LOAD_FAILURE = 'failed'
const TESTAROSSA_LOADING = 'loading'
const TESTAROSSA_LOADED = 'loaded'

let loadingState

function isLoggedIn ({ state }) {
  const { auth } = state

  return (
    getAuthIsLoggedIn(auth)
  )
}

function isLoggedInOneTrustDisabled ({ state }) {
  const { auth } = state

  return (
    getAuthIsLoggedIn(auth) && !oneTrustEnabled
  )
}

function isLoggedInOneTrustEnabled ({ state }) {
  const { auth } = state

  return (
    getAuthIsLoggedIn(auth) && oneTrustEnabled
  )
}

function v12Configure (getState, dispatch) {
  // if testarossa has already initialized, is disabled, or failed to load
  // we should call subscribe ourselves
  if (isDisabled()
    || hasInitialized()
    || global.v12Failure === true) {
    subscribe(getState, dispatch, global.V12)
    return
  }
  // if we made it here, v12 has not loaded quite yet,
  // so we assign the v12Configure function to the window for pickup
  global.v12Configure = (v12) => {
    subscribe(getState, dispatch, v12)
  }
}

function subscribe (getState, dispatch, v12) {
  const state = getState()
  const user = get(state, 'user', Map())
  const auth = get(state, 'auth', Map())

  // if v12 failed to load...
  if (global.v12Failure === true
    || v12.isDisabled()) {
    dispatch(setInitialState({
      failure: global.v12Failure,
      initialized: true,
      records: [],
      context: {},
    }))
    return
  }

  const userId = auth.get('uid', undefined)
  const lang = user.getIn(['data', 'language'], List())
  const accountId = auth.get('userAccountId')
  const userTenureDays = user.getIn(['data', 'userTenureDays'], 0)
  const initialContext = {
    lang: lang.get(0, 'en'),
    local_date: stamp,
    client: 'WAP',
  }
  const subjectRecords = v12.getState('records', [])

  if (userId && userId > 0) {
    initialContext.user_id = userId
  }

  if (accountId) {
    initialContext.user_account = accountId
  }

  if (userTenureDays) {
    initialContext.user_tenure_days = userTenureDays
  }

  v12.setContext(() => (initialContext))

  dispatch(setInitialState({
    records: subjectRecords,
    initialized: v12.hasInitialized(),
    context: v12.getContext(),
    failure: false,
  }))

  // listen for when V12 is initialized
  v12.addListener('resourcer.initialized', () => {
    dispatch(setInitialized(true))
  })
  // listen for context change
  v12.addListener('context.updated', (context) => {
    dispatch(setReduxContext(context))
  })
  // listen for records updating
  v12.addListener('records.updated', (records) => {
    dispatch(setRecords(records))
  })
}

function setV12Context (context) {
  const now = new Date()
  const state = assign({}, context)
  if (now.getHours() > stamp.getHours()) {
    stamp.setHours(now.getHours())
    set(state, 'local_date', stamp)
  }
  setContext(() => (state))
}

function setV12Options (getState) {
  // V12 always tries to obtain a user once script is loaded,
  // but the context is not configured yet.
  // If there is no testarossa uuid saved in the localStorage/sessionStorage/cookies,
  // a new user will be created. But at this point we already have a userId
  // so we can pass it as V12Options and avoid new user creation
  const state = getState()
  const auth = get(state, 'auth', Map())
  const userId = auth.get('uid', undefined)

  if (userId && userId > 0) {
    global.V12Options = { underlyingId: userId }
  }
}

async function loadTestarossa (getState, dispatch) {
  if (!global.V12 && !global.v12Configure
    && loadingState !== TESTAROSSA_LOADING
    && loadingState !== TESTAROSSA_LOADED) {
    loadingState = TESTAROSSA_LOADING

    setV12Options(getState)

    const { default: scriptjs } = await import('scriptjs')
    scriptjs(`${serverTestarossa}assets/1.136.30/v12.js`, () => {
      if (!global.V12) {
        global.v12Failure = true
        loadingState = TESTAROSSA_LOAD_FAILURE
      } else {
        loadingState = TESTAROSSA_LOADED
      }
      v12Configure(getState, dispatch)
    })
  }
}

export function loadTestarossaBootstrapPhase ({ after }) {
  return after(
    SET_APP_BOOTSTRAP_PHASE,
    async ({ state, getState, dispatch }) => {
      const { app, auth } = state
      const phase = app.get('bootstrapPhase')

      if (phase !== BOOTSTRAP_PHASE_INIT) return

      const uid = auth.get('uid')
      const authToken = auth.get('jwt')

      if (canSetCookie(uid, authToken)) {
        await loadTestarossa(getState, dispatch)
      }
    },
  )
    .when(isLoggedInOneTrustDisabled)
}

export function loadTestarossaBootstrapOneTrustEnabled ({ after }) {
  return after(
    SET_APP_BOOTSTRAP_PHASE,
    async ({ state, getState, dispatch }) => {
      const { app } = state
      const phase = app.get('bootstrapPhase')

      if (phase !== BOOTSTRAP_PHASE_INIT) return

      await loadTestarossa(getState, dispatch)
    },
  )
    .when(isLoggedInOneTrustEnabled)
}

export function disableTestarossaBootstrapPhase ({ after }) {
  return after(
    SET_APP_BOOTSTRAP_PHASE,
    async ({ state }) => {
      const { app, resolver } = state
      const phase = app.get('bootstrapPhase')

      if (phase !== BOOTSTRAP_PHASE_INIT) return

      const testarossaEnabled = resolver.getIn(['query', 'testarossa_enabled'])
      const testarossaIsDisabled = testarossaEnabled === 'false' || testarossaEnabled === '0'

      if (global.sessionStorage) {
        global.sessionStorage.setItem('testarossa_enabled', !testarossaIsDisabled)
      }
    },
  )
}

// -----------------------------------
// Watcher for checking when setCookieBannerAccepted is fired
// -----------------------------------
export function loadTestarossaCookieBannerAccepted ({ after }) {
  return after(
    SET_COOKIE_BANNER_ACCEPTED,
    async ({ dispatch, getState }) => {
      await loadTestarossa(getState, dispatch)
    },
  )
    .when(isLoggedIn)
}

// -----------------------------------
// Watcher for checking when a user logs in
// -----------------------------------
export function onTestarossaSetAuthLoginSuccess ({ after }) {
  return after(
    SET_AUTH_DATA,
    async ({ dispatch, getState, action }) => {
      const { payload } = action
      const loginSuccess = payload.data.success

      if (loginSuccess) {
        await loadTestarossa(getState, dispatch)
      }
    },
  )
}

export function setPath ({ before }) {
  return before(SET_RESOLVER_LOCATION, ({ action, state }) => {
    const { payload = {} } = action
    const { location = {} } = payload
    const { pathname } = location
    const { user = Map() } = state
    const uid = user.getIn(['data', 'uid'], null)
    const languages = user.getIn(['data', 'language'], List())
    const context = { path: pathname }
    if (uid && uid > 0) {
      set(context, 'user_id', uid)
    }
    if (languages && languages.size) {
      set(context, 'lang', toLower(languages.get(0)))
    }
    setV12Context(context)
  })
    .when(isLoggedIn)
}

export function setLang ({ before }) {
  return before([
    SET_USER_DATA_LANGUAGE,
    SET_USER_DATA_LANGUAGE_PRIMARY,
  ], ({ state, action }) => {
    let lang
    if (isNil(action)) {
      const user = get(state, 'user', Map())
      lang = user.getIn(['data', 'language'], List())
    } else {
      lang = get(action, ['payload', 'value'], 'en')
    }
    if (List.isList(lang)) {
      lang = lang.first()
    }
    setV12Context({
      lang: toLower(lang),
    })
  })
    .when(isLoggedIn)
}

export function setInboundTracking ({ before }) {
  return before(SET_INBOUND_TRACKING_DATA_INITIALIZED, ({ action }) => {
    const { payload } = action
    const { data } = payload
    const utmCampaign = data.get('utm_campaign', null)
    if (utmCampaign) {
      setV12Context({
        utm_campaign: utmCampaign,
      })
    }
  })
    .when(isLoggedIn)
}

export function setOnboardingChoices ({ before }) {
  return before(SET_ONBOARDING_COMPLETED, ({ state }) => {
    const { onboarding = Map() } = state
    const selection = onboarding.get('selection', Map())
    const tid = selection.get('tid')
    if (tid) {
      setV12Context({
        onboarding_choices: [tid],
      })
    }
  })
    .when(isLoggedIn)
}

export function setUserId ({ before }) {
  return before(SET_AUTH_DATA, ({ action }) => {
    const { payload = {} } = action
    const { data = {} } = payload
    const { uid, userAccountId, userTenureDays } = data

    if (uid && uid > 0) {
      setV12Context({
        user_account: userAccountId,
        user_id: uid,
        user_tenure_days: userTenureDays,
      })
    }
  })
}

export function setSignup ({ before }) {
  return before(SET_CHECKOUT_ORDER_DATA, ({ action }) => {
    const { payload = {} } = action
    const { success, userIsNew, newUsername } = payload
    if (success && userIsNew && newUsername) {
      setV12Context({ signup_complete: true })
    }
  })
    .when(isLoggedIn)
}
